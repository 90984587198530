// extracted by mini-css-extract-plugin
export var buttons = "bn_cl";
export var container = "bn_b";
export var image = "bn_g";
export var innerText = "bn_fg";
export var logo = "bn_bk";
export var p = "bn_ff";
export var section = "bn_d";
export var short = "bn_fh";
export var tall = "bn_fj";
export var textContainer = "bn_bY";