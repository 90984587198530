import React, { useState, useEffect, useContext } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './hero.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import Wave from 'react-wavify'
import { Context } from '../../../context'
import InfoButton from '../infoButton/infoButton'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const [current, setCurrent] = useState(0);
  const [pause, setPause] = useState(true);
  const [title, setTitle] = useState(block.blockTitle);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 40 : 75)
  const context = useContext(Context);
 
  useEffect(() => {
    if (context && !context.prefersReducedMotion)
    setPause(false);
    const waveTimer = setTimeout(() => {
      setPause(true);
    }, 3000);

    return () => {
      clearTimeout(waveTimer);
    }
  }, [current])

  useEffect(() => {
    const updateAmplitude = () => {
      setTimeout(() => {
        if (window.innerWidth > 600)
          setAmplitude(window.innerWidth / 40);
        else setAmplitude(window.innerWidth / 20);
      }, 1000)
    }
    
    updateAmplitude();
  }, [context && context.width])

  const exclude = ['Phones', 'Blog']

  useEffect(() => {
    if (block.blockTitle.includes('{{city}}')) {
      if (context.city)
        setTitle(block.blockTitle.replace('{{city}}', context.city));
      else
        setTitle(block.blockTitle.replace(', {{city}}', ''));
    }
  }, [context && context.city, title])

  return (
    <div className={styles.section + ` ${exclude.includes(block.blockTitle) ? `${styles.short}` : ""}`} id={block.type}>
      <div className={styles.container}>
        {block.heroImage &&
        <Image
          className={styles.image}
          src={block.heroImage}
          container
          style={{
            backgroundSize: block.image_width > 0 ? `${block.image_width}%` : 'cover',
            backgroundPosition: 'center ' + (block.image_position
              ? `${50 + block.image_position}%`
              : 'center')
          }}
        />}
        <div className={styles.textContainer + ` ${block.heroBody.length > 400 ? `${styles.tall}` : ''}`}>
          <h1>
            <ReactMarkdown>
              {block.heroSmallTitle}
            </ReactMarkdown></h1>
          {block.heroShowPageTitle && <h2>
            <ReactMarkdown>{title}</ReactMarkdown>
          </h2>}
          <p className={styles.p}><ReactMarkdown>{block.heroBody}</ReactMarkdown></p>
          <div className={styles.buttons}>
            {block.heroButtons && block.heroButtons.map(({sharedTextButton}, i) => (
              <SharedTextButton key={i} button={sharedTextButton} />
            ))}
          </div>
        </div> 
        <InfoButton />
      </div>
    </div>
  )
}