// extracted by mini-css-extract-plugin
export var body = "bM_c";
export var button = "bM_b9";
export var close = "bM_F";
export var container = "bM_b";
export var content = "bM_bs";
export var heading = "bM_G";
export var link = "bM_bp";
export var mobileHide = "bM_g3";
export var open = "bM_9";
export var section = "bM_d";
export var show = "bM_bj";
export var title = "bM_h";