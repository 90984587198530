// extracted by mini-css-extract-plugin
export var container = "bF_b";
export var feature = "bF_gL";
export var featureText = "bF_gM";
export var features = "bF_gK";
export var footer = "bF_cR";
export var footerText = "bF_cG";
export var icon = "bF_br";
export var section = "bF_d";
export var text = "bF_cr";
export var title = "bF_h";
export var waveOne = "bF_q";
export var waveThree = "bF_cJ";
export var waveTwo = "bF_n";
export var waves = "bF_cH";