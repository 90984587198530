// extracted by mini-css-extract-plugin
export var bottom = "f_s";
export var bottomCover = "f_v";
export var bottomImage = "f_t";
export var mobileWave = "f_w";
export var section = "f_d";
export var top = "f_l";
export var topImage = "f_m";
export var waveOne = "f_q";
export var waveOneImage = "f_r";
export var waveTwo = "f_n";
export var waveTwoImage = "f_p";