// extracted by mini-css-extract-plugin
export var caption = "bD_gJ";
export var grey = "bD_dg";
export var heading = "bD_G";
export var image = "bD_g";
export var left = "bD_bR";
export var reverse = "bD_dc";
export var right = "bD_bT";
export var section = "bD_d";
export var video = "bD_fT";
export var videoContainer = "bD_cx";
export var videoWrapper = "bD_cy";