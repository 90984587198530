// extracted by mini-css-extract-plugin
export var bottomCircle = "bJ_gV";
export var content = "bJ_bs";
export var description = "bJ_dh";
export var hide = "bJ_B";
export var icon = "bJ_br";
export var image = "bJ_g";
export var imageContainer = "bJ_gZ";
export var left = "bJ_bR";
export var nextYear = "bJ_gY";
export var right = "bJ_bT";
export var section = "bJ_d";
export var shrink = "bJ_gX";
export var title = "bJ_h";
export var topCircle = "bJ_gT";
export var wave = "bJ_gs";
export var waveContainer = "bJ_dJ";
export var year = "bJ_gW";