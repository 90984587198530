// extracted by mini-css-extract-plugin
export var buttons = "P_cl";
export var column = "P_b8";
export var columns = "P_b7";
export var container = "P_b";
export var down = "P_cM";
export var download = "P_cL";
export var footer = "P_cR";
export var information = "P_cK";
export var section = "P_d";
export var show = "P_bj";
export var text = "P_cr";
export var title = "P_h";
export var units = "P_cN";
export var up = "P_cQ";
export var upload = "P_cP";
export var waveOne = "P_q";
export var waveThree = "P_cJ";
export var waveTwo = "P_n";
export var waves = "P_cH";