// extracted by mini-css-extract-plugin
export var bottom = "bC_s";
export var contact = "bC_gt";
export var faqCat = "bC_gC";
export var faqCatDescription = "bC_gG";
export var faqCatTitle = "bC_gF";
export var faqCategories = "bC_gB";
export var faqIcon = "bC_gD";
export var footerText = "bC_cG";
export var icon = "bC_br";
export var iconContainer = "bC_b0";
export var iconText = "bC_gw";
export var immediate = "bC_gv";
export var inputContainer = "bC_c4";
export var linksSubtitle = "bC_gy";
export var linksTitle = "bC_gx";
export var search = "bC_cS";
export var searchInput = "bC_c5";
export var section = "bC_d";
export var selected = "bC_bb";
export var supportButton = "bC_gH";
export var supportLinks = "bC_gz";
export var top = "bC_l";
export var wave = "bC_gs";