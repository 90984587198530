// extracted by mini-css-extract-plugin
export var background = "bK_y";
export var backgroundContainer = "bK_fk";
export var bottomWave = "bK_cF";
export var buttons = "bK_cl";
export var container = "bK_b";
export var content = "bK_bs";
export var icon = "bK_br";
export var iconContainer = "bK_b0";
export var middleWave = "bK_cD";
export var negativeTop = "bK_g0";
export var overlap = "bK_g2";
export var section = "bK_d";
export var selected = "bK_bb";
export var tab = "bK_dQ";
export var tabText = "bK_dP";
export var title = "bK_h";
export var topWave = "bK_g1";