import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './promotions.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import Wave from 'react-wavify'
import { Context } from '../../../context'
import InfoButton from '../infoButton/infoButton'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const context = useContext(Context);
  const [current, setCurrent] = useState(0);
  const [pause, setPause] = useState(true);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 30 : 75)
  const length = block.promotionsPromotions.length;
  const style = typeof window !== 'undefined' ? document.documentElement.style : null;
  
  const ref = useCallback(el => {
    if (el && el.id === `promotions${current}`) {
      style.setProperty('--promotions-height', el.offsetHeight + 'px');
    }
  }, [current])

  useEffect(() => {
    if (context && !context.prefersReducedMotion) {
      setPause(false)
      const waveTimer = setTimeout(() => {
        setPause(true);
      }, 2000);
      // const sliderTimer = setTimeout(() => {
      //   setCurrent(current === length - 1 ? 0 : current + 1);
      // }, 8000)

      return () => {
        clearTimeout(waveTimer);
        // clearTimeout(sliderTimer);
      }
    }
  }, [current])

  useEffect(() => {
    const updateAmplitude = () => {
      setTimeout(() => {
        setAmplitude(window.innerWidth / 30)
      }, 1000)
    }
    
    updateAmplitude();
  }, [context])

  return (
    <div className={styles.section} id={block.type}>
      <div className={styles.markers}>
        {block.promotionsPromotions.map((promotion, i) => (
          <button onClick={() => setCurrent(i)} key={i} className={styles.marker + ` ${current === i ? `${styles.current}` : ""}`}>{current === i && i + 1}</button>
        ))}   
      </div>
      {block.promotionsPromotions.map((promotion, i) => (
        <div className={styles.promotions + ` ${current === i ? `${styles.current}` : ""}`} key={i} ref={ref} id={`promotions${i}`}>
          <Image
            className={styles.image}
            src={promotion.image}
            container
            fetchpriority={i === 0 ? 'high' : undefined}
            style={{
              backgroundSize: promotion.image_width > 0 ? `${promotion.image_width}%` : 'cover',
              backgroundPosition: 'center ' + (promotion.image_position
                ? `${50 + promotion.image_position}%`
                : 'center')
            }}
          />
          <div className={styles.textContainer}>
            <h2>{promotion.title}</h2>
            <ReactMarkdown className={styles.p}>{promotion.body}</ReactMarkdown>
            <div className={styles.buttons}>
              {promotion.buttons && promotion.buttons.length > 0 && promotion.buttons.map(({sharedTextButton}, i) => (
                <SharedTextButton key={i} button={sharedTextButton} />
              ))}
            </div>
          </div> 
        </div>
      ))}
      <InfoButton />
    </div>
  )
}