// extracted by mini-css-extract-plugin
export var active = "bc_8";
export var arrow = "bc_5";
export var border = "bc_dm";
export var button = "bc_b9";
export var column = "bc_b8";
export var columns = "bc_b7";
export var container = "bc_b";
export var description = "bc_dh";
export var dot = "bc_dr";
export var footer = "bc_cR";
export var footerText = "bc_cG";
export var heading = "bc_G";
export var icon = "bc_br";
export var includes = "bc_dp";
export var info = "bc_cs";
export var left = "bc_bR";
export var mobileOption = "bc_dq";
export var option = "bc_dk";
export var optionText = "bc_dl";
export var options = "bc_dj";
export var padding = "bc_dx";
export var plans = "bc_dn";
export var popular = "bc_dw";
export var price = "bc_ds";
export var right = "bc_bT";
export var section = "bc_d";
export var show = "bc_bj";
export var subHeading = "bc_H";
export var subText = "bc_dv";
export var suffix = "bc_dt";
export var title = "bc_h";
export var waveOne = "bc_q";
export var waveThree = "bc_cJ";
export var waveTwo = "bc_n";
export var waves = "bc_cH";