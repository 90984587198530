// extracted by mini-css-extract-plugin
export var answer = "bB_gr";
export var arrow = "bB_5";
export var body = "bB_c";
export var category = "bB_c3";
export var contactIcons = "bB_gk";
export var current = "bB_fc";
export var fade = "bB_gn";
export var footer = "bB_cR";
export var footerText = "bB_cG";
export var icon = "bB_br";
export var iconContainer = "bB_b0";
export var issue = "bB_gq";
export var issueContainer = "bB_gm";
export var issueText = "bB_gp";
export var issues = "bB_gl";
export var left = "bB_bR";
export var padding = "bB_dx";
export var right = "bB_bT";
export var section = "bB_d";
export var selectors = "bB_dV";
export var subtitle = "bB_cd";
export var title = "bB_h";