// extracted by mini-css-extract-plugin
export var appStores = "W_df";
export var grey = "W_dg";
export var icon = "W_br";
export var iconGroup = "W_dd";
export var image = "W_g";
export var left = "W_bR";
export var reverse = "W_dc";
export var right = "W_bT";
export var section = "W_d";
export var textSection = "W_db";