import React, { useRef, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import SearchResult from "../../components/header/blocks/search/searchResult/searchResult"
import { Footer, Header, Main } from '../../components'
import { Context } from '../context'
import './reset.scss'
import './global.scss'
import * as styles from './layout.module.scss'


type layoutProps = {
  children?: any,
  title: string,
  blocks?: [any]
  description: string,
  seo_image: string,
  metaTags: MetaTag[]
}

type MetaTag = {
  name: string,
  content: string
}

export default ({ children, title: siteTitle = '', blocks, description, seo_image, metaTags }: layoutProps) => {
  const context = useContext(Context);
  const ref = useRef(null);
  const { site: { siteMetadata: { config: { title, titleDivider } } } } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          config {
            title
            titleDivider
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== 'undefined') {

      const updateScroll = () => {
        context.setScroll(window.scrollY);
        if (window.scrollY > window.innerWidth * .1)
          context.setScrolled(true);
        else context.setScrolled(false);
      }
      
      updateScroll();

      context.setScroll(window.scrollY);

      window.addEventListener('scroll', updateScroll);
      
      return (() => {
        window.removeEventListener('scroll', updateScroll);
      })
    }
  }, [ref])

  return (
    <div className={styles.layout} ref={ref}>
      <Helmet>
        <html lang='en' />
        <title>{siteTitle + titleDivider + `${title || '404: Page Not Found'}`}</title>
        <meta name="facebook-domain-verification" content="0rsal78jxjwbus89m8ofpnh6zgq9wl" />
        <meta name="description" content={description} />
          <meta name="author" content='BTC Broadband' />
          <meta property="og:title" content={siteTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" itemProp="image" content={seo_image} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={typeof window !== 'undefined' ? location && location.href : ''} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:image" itemProp="image" content={seo_image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:card" content="summary_large_image" />
        {metaTags && metaTags.map((tag, i) => (
          <meta key={i} name={tag.name} content={tag.content} />
        ))}
      </Helmet>
      <Header />
      <SearchResult />
      <Main blocks={blocks}>
        {children}
      </Main>
      <Footer />
    </div>
  )
}

