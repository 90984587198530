// extracted by mini-css-extract-plugin
export var arrow = "bh_5";
export var body = "bh_c";
export var circle = "bh_d0";
export var customer = "bh_dZ";
export var feedback = "bh_dY";
export var feedbacks = "bh_dW";
export var footer = "bh_cR";
export var footerText = "bh_cG";
export var left = "bh_bR";
export var nameLocation = "bh_d1";
export var padding = "bh_dx";
export var right = "bh_bT";
export var section = "bh_d";
export var selected = "bh_bb";
export var selectors = "bh_dV";
export var subtitle = "bh_cd";
export var text = "bh_cr";
export var title = "bh_h";
export var wrapper = "bh_dX";