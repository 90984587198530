// extracted by mini-css-extract-plugin
export var active = "bz_8";
export var categories = "bz_c2";
export var category = "bz_c3";
export var icon = "bz_br";
export var icons = "bz_bt";
export var image = "bz_g";
export var member = "bz_gh";
export var memberTitle = "bz_gj";
export var name = "bz_fq";
export var placeholder = "bz_6";
export var section = "bz_d";
export var show = "bz_bj";
export var subtitle = "bz_cd";
export var team = "bz_gg";