import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    fetchpriority?: 'high' | 'low' | 'auto';
  }
}

type imageProps = {
  className: string,
  container?: boolean,
  src: string,
  alt?: string,
  title?: string,
  style?: any,
  children?: React.ReactNode,
  onClick?: any,
  gradient?: string,
  id?: any,
  fetchpriority?: 'high' | 'low' | 'auto'
}

const handleDoNothing = () => null

export default ({ className, container, src, alt = '', title = '', style, children, onClick, gradient = '', id = Math.random(), fetchpriority }: imageProps) => {
  // const { allFile: { nodes: images } } = useStaticQuery(graphql`
  //   {
  //     allFile(filter: {relativeDirectory: {eq: "images"}}) {
  //       nodes {
  //         publicURL
  //         relativePath
  //       }
  //     }
  //   }
  // `)

  // const image = images && images.length && images.find(image => image.relativePath === 'images/' + src.split('/').pop())
  const image = src && src[0] === '/' ? src : '/' + src;
  // console.log(style)
  return (
    !container ? (
      <img id={id} onClick={onClick || handleDoNothing} className={className} style={{  ...style }} src={image} alt={alt || title} title={title || alt} fetchpriority={fetchpriority}/>
    ) : (
      <div id={id} onClick={onClick || handleDoNothing} className={className} style={{ backgroundRepeat: 'no-repeat',  ...style, backgroundImage: `${gradient ? `${gradient}, ` : ''}url("${image}")` }} fetchpriority={fetchpriority}>{children}</div>
    )
  )
}