// extracted by mini-css-extract-plugin
export var background = "bt_y";
export var backgroundContainer = "bt_fk";
export var bottomImage = "bt_t";
export var bottomImageContainer = "bt_fp";
export var buttons = "bt_cl";
export var container = "bt_b";
export var imagesContainer = "bt_fn";
export var middleWave = "bt_cD";
export var section = "bt_d";
export var text = "bt_cr";
export var textContainer = "bt_bY";
export var title = "bt_h";
export var topImage = "bt_m";
export var waveOverlay = "bt_fm";