// extracted by mini-css-extract-plugin
export var buttons = "bG_cl";
export var left = "bG_bR";
export var media = "bG_gQ";
export var right = "bG_bT";
export var section = "bG_d";
export var selected = "bG_bb";
export var signature = "bG_gP";
export var testimonial = "bG_gN";
export var text = "bG_cr";
export var textContainer = "bG_bY";
export var video = "bG_fT";