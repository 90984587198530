// extracted by mini-css-extract-plugin
export var buttons = "bf_cl";
export var container = "bf_b";
export var content = "bf_bs";
export var icon = "bf_br";
export var iconContainer = "bf_b0";
export var image = "bf_g";
export var section = "bf_d";
export var selected = "bf_bb";
export var tab = "bf_dQ";
export var tabText = "bf_dP";