// extracted by mini-css-extract-plugin
export var bottomContainer = "M_cC";
export var buttons = "M_cl";
export var icon = "M_br";
export var link = "M_bp";
export var section = "M_d";
export var social = "M_cB";
export var text = "M_cr";
export var title = "M_h";
export var topContainer = "M_cz";
export var videoContainer = "M_cx";
export var videoWrapper = "M_cy";