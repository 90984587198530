// extracted by mini-css-extract-plugin
export var bottomWave = "bs_cF";
export var button = "bs_b9";
export var buttonContainer = "bs_cj";
export var buttonFooter = "bs_fl";
export var container = "bs_b";
export var middleWave = "bs_cD";
export var section = "bs_d";
export var subtitle = "bs_cd";
export var textContainer = "bs_bY";
export var title = "bs_h";